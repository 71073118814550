import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CronEditorComponent } from './features/cron-editor/cron-editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { CronEditorModule } from './References/cron-editor/cron-editor.module';
import { HTMLEscapeUnescapeModule } from 'html-escape-unescape';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './features/login/login.component';
import { AuthGuard } from './shared/guards/auth-guard';
import { TabsModule } from 'ngx-bootstrap';
import { ArchwizardModule, WizardComponent } from 'angular-archwizard';
@NgModule({
  declarations: [
    AppComponent,
    CronEditorComponent,
    LoginComponent
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    TabViewModule,
    DropdownModule,
    PanelModule,
    ButtonModule,
    DataViewModule,
    TableModule,
    InputTextareaModule,
    CronEditorModule,
    HTMLEscapeUnescapeModule,
    NgxSpinnerModule,
    TabsModule.forRoot(),
    ArchwizardModule
  ],
  providers: [
    HttpClientModule,
    AuthGuard,
    WizardComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
