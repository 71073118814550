import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CronEditorComponent } from './features/cron-editor/cron-editor.component';
import { LoginComponent } from './features/login/login.component';
import { AuthGuard } from './shared/guards/auth-guard';

const routes: Routes = [
  { path: '',
  redirectTo: '/scheduler',
  pathMatch: 'full'
},
  {
    path: 'scheduler',
    component: CronEditorComponent,
    canActivate: [AuthGuard]
},
{
  path: 'login',
  component: LoginComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
