import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CronEditorModel } from './cron-editor.model';
import { AppConstants } from 'src/app/shared/constants/AppConstants';
import { isJsObject } from '@angular/core/src/change_detection/change_detection_util';
import { JobHistory } from './JobHistory.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CronEditorCrudService {

  token = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImQ1ZmI1NTczNGU5YjlhYzc0ZThhYzZkZjQ4MDJjMjA2IiwidHlwIjoiSldUIn0.eyJuYmYiOjE1NTgzNDkzNDksImV4cCI6MTU1ODM1Mjk0OSwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDQiLCJhdWQiOlsiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDQvcmVzb3VyY2VzIiwiYXBpMSJdLCJjbGllbnRfaWQiOiJDbGllbnQxIiwic3ViIjoiMSIsImF1dGhfdGltZSI6MTU1ODM0OTM0OSwiaWRwIjoibG9jYWwiLCJzY29wZSI6WyJhcGkxIl0sImFtciI6WyJwd2QiXX0.qXsz3pUFBAicKqwAg8Uim3P9Y7yZOWSAC81jMnoqeuF16n7d2V1owVJx3hVJ3h3kWI0gVI9MQ77UsQvchE_8kEW9STJXi15dbWWe69_rjJddmp2symjd_c07m0s25sK1rQ7gUeWDRfx3gGKE-U7idhPen0QrdFSJ_DswcW8WODerMa_SDkE64ATyg_ltL0WjLKXHJH1gmCNlaSUyrQ3-mQDCnvoCm-LwMUl-mViLzclpvfEaU_7nUv6JYUes8K7yH7Z3Jn1A6PwAz1xMb6P0-Yb0zSFOS4VcwlFqpKt1-44oOLqXR2ZZHpKNwedpJX3xe9s8FVUC7nivL4wzed_GLg";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
  };

  constructor(private http: HttpClient) {

  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAllJobs(): Observable<CronEditorModel[]> {
    return this.http.get<CronEditorModel[]>(environment.endPoint + '/api/CronEditor/GetAllJobs', this.httpOptions);
  }

  getJobById(jobId: string): Observable<CronEditorModel> {
    return this.http.get<CronEditorModel>(environment.endPoint + '/api/CronEditor/GetJobById?jobId=' + jobId,
      this.httpOptions);
  }

  getJobHistoryById(jobId: string): Observable<JobHistory[]> {
    return this.http.get<JobHistory[]>(environment.endPoint + '/api/CronEditor/GetJobHistoryById?jobId=' + jobId,
      this.httpOptions);
  }

  createJob(cronEditorModel: CronEditorModel): Observable<CronEditorModel> {
    return this.http.post<CronEditorModel>(environment.endPoint + '/api/CronEditor/SaveJob',
      cronEditorModel, this.httpOptions);
  }
  updateJob(cronEditorModel: CronEditorModel): Observable<CronEditorModel> {
    return this.http.put<CronEditorModel>(environment.endPoint + '/api/CronEditor/UpdateJob/',
      cronEditorModel, this.httpOptions);
  }
  deleteJobById(jobId: string): Observable<CronEditorModel> {
    return this.http.delete<CronEditorModel>(environment.endPoint + '/api/CronEditor/DeleteJob?jobId=' + jobId,
      this.httpOptions);
  }
}
