import { SelectItem } from 'primeng/components/common/selectitem';
import { JobHistory } from './JobHistory.model';

export class CronEditorModel {  
    _id: string;      
    cronExpression : string;
    serviceEndPoint: string;
    serviceActions : string;
    serviceParameters : string;
    secretKey : string;
    userName: string;
    password: string;
    jobQueue : string;
    timeZone : string; 
    lastExecution : string;
    nextExecution : string;
}  